<template>
  <div class="about">
    <HeaderEl pageName="Contorol" />
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/ctr/create">Create Card</router-link> |
      <router-link to="/ctr/delete">Manage Questions</router-link>
    </div>
    <FooterEl/>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderEl from "@/components/HeaderEl.vue";
import FooterEl from "@/components/FooterEl.vue";

export default {
  name: "Contorl",
  components: {
    HeaderEl,
    FooterEl,
  },
};
</script>
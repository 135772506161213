<template>
  <div class="thanks">
    <HeaderEl pageName="Thanks" />
    <main>
        <h1>Thanks!!</h1>
    </main>
    <FooterEl/>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderEl from "@/components/HeaderEl.vue";
import FooterEl from "@/components/FooterEl.vue";

export default {
  name: "Thanks",
  components: {
    HeaderEl,
    FooterEl,
  },
};
</script>
<style>
body {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
main {
  flex: 1;
  min-height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>